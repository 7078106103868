<template>
	<div class="body">
		<div class="go-back">
			<Button @click="$router.back(-1)">返回</Button>
		</div>
		<Form :model="FormData.plugin" :label-width="120" ref="plugin_form" :label-colon="true" :rules="rules_plugin"
			class="form">
			<div class="fi-title">1、插件信息（展示于服务端插件-插件市场）</div>
			<div class="fi-item">
				<FormItem label="名称" prop="name">
					<Input placeholder="请输入插件名称" v-model="FormData.plugin.name" style="width: 200px;"></Input>
				</FormItem>
				<FormItem label="简介" prop="simple">
					<Input placeholder="请输入插件简介" v-model="FormData.plugin.simple" style="width: 200px"></Input>
				</FormItem>
				<FormItem label="金额" prop="money">
					<Input placeholder="请输入插件金额" v-model="FormData.plugin.money" type="number"
						style="width: 200px"></Input>
				</FormItem>
				<FormItem label="图标" prop="icon">
					<div class="fi-tips">*建议上传尺寸到达72px*72px</div>
					<img v-if="FormData.plugin.icon" :src="serveUrl+FormData.plugin.icon" alt="" class="fi-icon">
					<Uploads class="fi-upload" :data="{token: getToken()}" @upload_succ="uploads0_succ"
						:action="apiUrl+'/adm/upload_object'"></Uploads>
				</FormItem>
				<FormItem label="插件详情图片" prop="desc">
					<div class="fi-tips">*展示于商家后台插件模板-插件市场-插件详情，不论大小</div>
					<img v-if="FormData.plugin.desc" :src="serveUrl+FormData.plugin.desc" alt="" class="fi-icon">
					<Uploads class="fi-upload" :data="{token: getToken()}" @upload_succ="uploads1_succ"
						:action="apiUrl+'/adm/upload_object'"></Uploads>
				</FormItem>
			</div>
		</Form>
		<div class="form">
			<div class="fi-title">2、前端插件信息（展示于客户端个人中心-必备工具）</div>
			<div class="fi-item">
				<Row v-for="(item,index) in FormData.banner" align="middle" :key="index" :gutter="30"
					style="margin-top: 10px;">
					<Col>
					<span>名称：</span>
					<Input placeholder="请输入名称" v-model="item.name" style="width: 200px;"></Input>
					</Col>
					<Col label="跳转地址">
					<span>跳转地址：</span>
					<Input placeholder="请输入跳转地址" v-model="item.link" style="width: 200px"></Input>
					</Col>
					<span style="margin-left: 20px;">图标：</span>
					<img v-if="item.img" :src="serveUrl+item.img" alt="" class="fi-icon">
					<Uploads class="fi-upload" @on-progress="upload_index = index" :data="{token: getToken()}"
						@upload_succ="uploads2_succ" :action="apiUrl+'/adm/upload_object'">
					</Uploads>
					<Button @click="add_banner" v-if="index == 0" type="primary" icon="ios-add"
						style="width: 70px;margin-left: 20px"></Button>
					<Button @click="del_banner(index)" v-if="index != 0" type="error"
						style="width: 70px;margin-left: 20px" icon="ios-trash-outline"></Button>
				</Row>
			</div>
		</div>
		<!-- <div class="fi-title">3、后台路由（作用于商家后台菜单栏）</div>
		<div class="fi-item">
			<Form :model="FormData.up_route" :label-width="100" :label-colon="true" class="form">
				<FormItem label="是否新增路由">
					<i-switch @on-change="route_switch" v-model="is_switch">
						<span slot="open">是</span>
						<span slot="close">否</span>
					</i-switch>
				</FormItem>
				<div v-if="!is_switch">
					<FormItem label="一级路由">
						<Select v-model="FormData.plugin.father_id" style="width: 100px;" @on-change="up_change">
							<Option v-for="(item,index) in route" :value="index" :key="item.id">{{ item.title }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="二级路由">
						<Input v-model="FormData.up_route.children.title" style="width: 200px;"
							placeholder="路由名称"></Input>
					</FormItem>
					<FormItem label="三级路由">
						<div v-for="(item,index) in FormData.up_route.children.children" :key="index"
							style="margin-bottom: 10px;">
							<Input placeholder="路由名称" style="width: 200px;" v-model="item.title"></Input>
							<Input placeholder="跳转路由" style="width: 200px;margin-left: 10px;"
								v-model="item.path"></Input>
							<Button style="margin-left: 10px;" icon="ios-add" type="primary"
								@click="add_up_completely">添加</Button>
							<Button v-if="index != 0" style="margin-left: 10px;" icon="ios-trash" type="error"
								@click="del_up_completely(index)">删除</Button>
						</div>
					</FormItem>
				</div>
			</Form>
			<Form :model="FormData.int_route" :label-width="100" :label-colon="true" class="form">
				<div v-if="is_switch">
					<div class="fi-title-insert-ft">一级路由信息</div>
					<FormItem label="路由名称">
						<Input placeholder="侧边菜单栏名称" v-model="FormData.int_route.title" style="width: 200px;"></Input>
					</FormItem>
					<FormItem label="路由图标">
						<img v-if="FormData.int_route.thumb" :src="serveUrl+FormData.int_route.thumb" alt=""
							class="fi-icon">
						<Uploads v-if="!FormData.int_route.thumb" class="fi-upload" :data="{dir:'plugin/temp'}"
							@upload_succ="uploads3_succ" :action="apiUrl+'/adm/upload_img'"></Uploads>
					</FormItem>
					<div class="fi-title-insert-ft">二级路由信息</div>
					<FormItem label="路由名称">
						<Input placeholder="侧边菜单栏名称" v-model="FormData.int_route.children.title"
							style="width: 200px;"></Input>
					</FormItem>
					<div class="fi-title-insert-ft">三级路由信息</div>
					<FormItem label="路由信息">
						<div v-for="(item,index) in FormData.int_route.children.children" :key="index"
							style="margin-bottom: 10px;">
							<Input placeholder="路由名称" style="width: 200px;" v-model="item.title"></Input>
							<Input placeholder="跳转路由" style="width: 200px;margin-left: 10px;"
								v-model="item.path"></Input>
							<Button style="margin-left: 10px;" icon="ios-add" type="primary"
								@click="add_completely">添加</Button>
							<Button v-if="index != 0" style="margin-left: 10px;" icon="ios-trash" type="error"
								@click="del_completely(index)">删除</Button>
						</div>
					</FormItem>
				</div>
			</Form> -->
		<!-- </div> -->
		<div class="submit-bt">
			<Button type="primary" @click="submit_button">保存</Button>
			<Button style="margin-left: 20px;" @click="$router.back(-1)">取消</Button>
		</div>
	</div>
</template>

<script>
	import Uploads from '../../../components/common/Uploads.vue'
	export default {
		data() {
			return {
				apiUrl: this.ApiUrl,
				serveUrl: 'https://',
				upload_index: 0,
				FormData: {
					plugin: {
						name: '', //插件名称
						icon: '', //插件图标
						desc: '', //插件详情图片
						simple: null, //插件简介
						money: null, //所需金额
						father_id: 0, //一级路由下标
						son_id: 0, //二级路由下标
					},
					//完全新增路由
					int_route: {
						path: null, //跳转链接
						status: 1, //是否显示
						thumb: '', //图标
						title: '', //路由名称
						//子路由
						children: {
							title: null,
							children: [{
								title: null,
								path: null
							}]
						}
					},
					//在原有的基础上添加路由
					up_route: {
						id: null, //选中一级路由ID
						children: {
							title: null,
							children: [{
								title: null,
								path: null
							}]
						}
					},
					banner: [{
						name: '', //banner名称
						img: '', //默认图标
						link: '', //跳转地址
					}]
				},
				route: {},
				is_switch: false,
				rules_plugin: {
					name: [{
						required: true,
						type: 'string',
						message: '请输入插件名称',
						trigger: 'blur'
					}],
					simple: [{
						required: true,
						message: '请输入插件简介',
						trigger: 'blur'
					}],
					money: [{
						required: true,
						message: '请输入正确的插件金额',
						trigger: 'blur'
					}],
					icon: [{
						required: true,
						message: '请上传插件图标',
						trigger: 'change'
					}],
					desc: [{
						required: true,
						message: '请上传插件详情图片',
						trigger: 'change'
					}]
				},
				rules_banner: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					link: [{
						required: true,
						message: '请输入跳转路由',
						trigger: 'blur'
					}],
					img: [{
						required: true,
						message: '请上传图标',
						trigger: 'change'
					}]
				}
			}
		},
		components: {
			Uploads
		},
		created() {
			// this.init()
		},
		methods: {
			// init() {
			// 	var _this = this

			// 	this.requestApi('/adm/adm_get_menu', {}).then(
			// 		(res) => {
			// 			_this.route = res.data
			// 			_this.FormData.up_route.id = _this.route[_this.FormData.plugin.father_id].id
			// 		}
			// 	)
			// },
			uploads0_succ(e) {
				this.FormData.plugin.icon = e.pic_url
			},
			uploads1_succ(e) {
				this.FormData.plugin.desc = e.pic_url
			},
			uploads2_succ(e) {
				this.FormData.banner[this.upload_index].img = e.pic_url
			},
			// uploads3_succ(e) {
			// 	this.FormData.int_route.thumb = e.pic_url
			// },
			// route_switch(e) {
			// 	this.is_switch = e
			// },
			// up_change(e) {
			// 	console.log(e)
			// },
			// //完全新增-添加三级路由
			// add_completely() {
			// 	let data = {
			// 		title: null,
			// 		status: 1,
			// 		path: null,
			// 		thumb: '',
			// 		level: 3
			// 	}
			// 	this.FormData.int_route.children.children.push(data)
			// },
			// //完全新增-删除三级路由
			// del_completely(index) {
			// 	this.FormData.int_route.children.children.splice(index, 1)
			// },
			// //添加三级路由
			// add_up_completely() {
			// 	let data = {
			// 		title: null,
			// 		status: 1,
			// 		path: null,
			// 		thumb: '',
			// 		level: 3
			// 	}
			// 	this.FormData.up_route.children.children.push(data)
			// },
			// //删除三级路由
			// del_up_completely(index) {
			// 	this.FormData.up_route.children.children.splice(index, 1)
			// },
			//添加banner
			add_banner() {
				this.FormData.banner.push({
						name: '', //banner名称
						img: '', //默认图标
						link: '' //跳转地址
					})
			},
			//删除banner
			del_banner(index) {
				this.FormData.banner.splice(index, 1)
			},
			//保存提交
			submit_button() {

				var _this = this

				let data = this.FormData

				data.is_switch = this.is_switch

				// if(data.is_switch == true) {
				// 	if(!data.int_route.title || !data.int_route.thumb || !data.int_route.children.title) {
				// 		return this.$Message.error('请将路由内容填写完整')
				// 	}
				// 	for(let i=0;i<data.int_route.children.children.length;i++) {
				// 		if(!data.int_route.children.children[i].title || !data.int_route.children.children[i].path) {
				// 			return this.$Message.error('请将路由内容填写完整')
				// 		}
				// 	}
				// }else {
				// 	if(!data.up_route.id || !data.up_route.children.title) {
				// 		console.log(data.up_route.id)
				// 		console.log(data.up_route.children.title)
				// 		return this.$Message.error('请将路由内容填写完整')
				// 	}
				// 	for(let i=0;i<data.up_route.children.children.length;i++) {
				// 		if(!data.up_route.children.children[i].title || !data.up_route.children.children[i].path) {
				// 			return this.$Message.error('请将路由内容填写完整')
				// 		}
				// 	}
				// }
				this.$refs.plugin_form.validate(valib => {
					if (valib) {
						this.requestApi('/adm/add_plugin', {
							data
						}).then(
							(res) => {
								if (res.code == 200) {
									_this.$Message.success(res.msg)

									_this.$router.push('/plugin/list')
								} else {
									_this.$Message.error(res.msg)
								}
							}
						)
					} else {
						return this.$Message.error('请将内容填写完整')
					}
				})
			}
		}
	}
</script>

<style>
	.body {
		padding: 20px;
		background-color: #fff;
	}

	.form {
		/* margin-top: 15px; */
	}

	.submit-bt {
		margin-top: 20px;
		text-align: right;
	}

	.form .fi-title {
		padding-left: 10px;
		margin-top: 10px;
		font-size: 16px;
		font-weight: 500;
		display: block;
		border-left: rgb(251, 176, 59) 2px solid;
	}

	.form .fi-item {
		padding: 20px;
		border: rgb(220, 222, 226) 1px dashed;
		margin-top: 10px;
	}

	.go-back {
		text-align: right;
	}

	.fi-upload {
		float: left;
		width: 77px;
		height: 77px;
		box-sizing: border-box;
	}

	.fi-icon {
		display: block;
		float: left;
		margin-right: 12px;
		width: 77px;
		height: 77px;
	}

	.fi-tips {
		font-size: 12px;
		color: #999999;
	}

	.fi-title-insert-ft {
		font-weight: bold;
	}
</style>
